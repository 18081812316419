<template>
  <div>
    <b-card no-body>
      <div class="m-2">
        <b-row class="align-items-center">
          <b-col
            cols="12"
            md="4"
            class="d-flex justify-content-start mb-1 mb-md-0"
          >
            <label>Zeige</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>Einträge</label>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="d-flex justify-content-center mb-1 mb-md-0"
          >
            <b-button-group size="sm">
              <b-button
                variant="outline-primary"
                @click="setStatusFilter('open')"
              >
                Offen
              </b-button>
              <b-button
                variant="outline-primary"
                @click="setStatusFilter('progress')"
              >
                Bearbeitung
              </b-button>
              <b-button
                variant="outline-primary"
                @click="setStatusFilter('done')"
              >
                Erledigt
              </b-button>
              <b-button
                variant="outline-primary"
                @click="setStatusFilter('closed')"
              >
                Geschlossen
              </b-button>
            </b-button-group>
          </b-col>
          <b-col cols="12" md="4" class="d-flex justify-content-end">
            <div>
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                placeholder="Suche..."
                type="search"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-card no-body>
          <b-table
            ref="refListTable"
            striped
            hover
            responsive
            :items="filteredEntries"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            show-empty
            empty-text="Keine Einträge gefunden"
            @row-clicked="onRowClicked"
          >
            <template #cell(created_at)="data">
              {{ getFormattedDate(data.item.created_at) }}
            </template>

            <template #cell(feedbackType)="data">
              {{ getFeedbackTypeTranslation(data.item.feedbackType) }}
            </template>

            <template #cell(priority)="data">
              <b-badge pill :variant="getPriorityVariant(data.item.priority)">
                {{ getPriorityTranslation(data.item.priority) }}
              </b-badge>
            </template>

            <template #cell(status)="data">
              <b-badge pill :variant="getStatusVariant(data.item.status)">
                {{ getStatusTranslation(data.item.status) }}
              </b-badge>
            </template>
            <template #cell(files)="data">
              <b-badge v-if="data.item.files.length > 0" pill variant="success">
                {{ data.item.files.length }}
              </b-badge>
              <b-badge v-else pill variant="secondary">
                {{ data.item.files.length }}
              </b-badge>
            </template>
          </b-table>
        </b-card>
      </div>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Zeige {{ dataMeta.from }} bis {{ dataMeta.to }} von
              {{ dataMeta.of }} Einträge</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="getTotalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BPagination,
  BBadge,
  BDropdown,
  BDropdownItem,
  BButtonGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import helper from '@/helper/helper'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import tableHelper from '../Table+Helper'
import feedbackHelper from './FeedbackHelper'

export default {
  setup() {
    const { getFormattedDate } = helper()
    const {
      getPriorityTranslation,
      getFeedbackTypeTranslation,
      getStatusTranslation,
      getStatusVariant,
      getPriorityVariant,
    } = feedbackHelper()

    const {
      perPageOptions,
      perPage,
      filter,
      totalRows,
      currentPage,
      refListTable,
      dataMeta,
      refetchData,
    } = tableHelper()

    return {
      perPageOptions,
      perPage,
      filter,
      totalRows,
      currentPage,
      refListTable,
      dataMeta,
      getFormattedDate,
      refetchData,

      getPriorityTranslation,
      getFeedbackTypeTranslation,
      getStatusTranslation,
      getStatusVariant,
      getPriorityVariant,
    }
  },
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BPagination,
    BBadge,
    vSelect,
    StatisticCardVertical,
    BDropdown,
    BDropdownItem,
    BButtonGroup,
    feedbackHelper,
  },
  data() {
    return {
      sortBy: 'id',
      sortDesc: true,
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'title',
          label: 'Titel',
        },
        {
          key: 'description',
          label: 'Beschreibung',
        },
        {
          key: 'files',
          label: 'Anhang',
          sortable: true,
        },
        {
          key: 'created_at',
          label: 'Erstellt am',
        },
        {
          key: 'user',
          label: 'Erstellt von',
        },
        {
          key: 'feedbackType',
          label: 'Art',
          sortable: true,
        },
        {
          key: 'priority',
          label: 'Priorität',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true,
        },
      ],
      statusFilter: '',
      filteredEntries: [],
    }
  },
  computed: {
    getTotalRows() {
      return this.filteredEntries.length
    },
  },
  watch: {
    filter() {
      this.applyFilters()
    },
    statusFilter() {
      this.applyFilters()
    },
  },
  created() {
    this.applyFilters()
  },
  methods: {
    onRowClicked(item) {
      this.$router.push(`/feedback-view/${item.id}`)
    },
    setStatusFilter(status) {
      this.statusFilter = status
    },
    applyFilters() {
      const filteredEntries = this.$store.getters.feedbackEntries.filter(
        item => {
          // eslint-disable-next-line operator-linebreak
          const searchTermFilter =
            // eslint-disable-next-line operator-linebreak
            !this.filter ||
            // eslint-disable-next-line operator-linebreak
            item.title.toLowerCase().includes(this.filter.toLowerCase()) ||
            item.description.toLowerCase().includes(this.filter.toLowerCase())

          // eslint-disable-next-line operator-linebreak
          const statusFilter =
            !this.statusFilter || item.status === this.statusFilter

          return searchTermFilter && statusFilter
        }
      )

      this.filteredEntries = filteredEntries
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
