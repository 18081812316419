<template>
  <div>
    <!-- {{ feedback }} -->
    <b-card>
      <h2 class="mb-3">Feedback melden</h2>
      <b-form
        ref="feedbackForm"
        enctype="multipart/form-data"
        @submit.prevent="submitFeedback"
      >
        <b-row>
          <b-col cols="12" xl="6" lg="6" md="12" sm="12">
            <b-form-group
              id="input-group-title"
              label="Titel"
              label-for="input-title"
            >
              <b-form-input
                id="input-title"
                v-model="feedback.title"
                required
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" xl="6" lg="6" md="12" sm="12">
            <b-form-group
              id="input-group-feedback-type"
              label="Feedback Art"
              label-for="input-feedback-type"
            >
              <v-select
                :value="getFeedbackTypeTranslation(feedback.feedbackType)"
                label="label"
                :options="feedbackTypeOptions"
                required
                :clearable="false"
                @input="changedType"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              id="input-group-description"
              label="Beschreibung"
              label-for="input-description"
            >
              <b-form-textarea
                id="input-description"
                v-model="feedback.description"
                required
                :rows="5"
                :cols="30"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" xl="6" lg="6" md="12" sm="12">
            <b-form-group
              id="input-group-priority"
              label="Priorität"
              label-for="input-priority"
            >
              <v-select
                :value="getPriorityTranslation(feedback.priority)"
                label="label"
                :options="priorityOptions"
                required
                :clearable="false"
                @input="changedPriority"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" xl="6" lg="6" md="12" sm="12">
            <b-form-group
              id="input-group-assignee"
              label="Anhang"
              label-for="input-assignee"
            >
              <b-form-file
                id="attachments"
                :placeholder="
                  this.attachments.length
                    ? 'Dateien ausgewählt'
                    : 'Screenshots, Bilder, PDF, etc. auswählen...'
                "
                multiple
                @change="onFilesChange"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <div class="text-left">
              <b-button type="submit" variant="primary"> Absenden </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import helper from '@/helper/helper'
import {
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BRow,
  BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import feedbackHelper from './FeedbackHelper'

export default {
  components: {
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BButton,
    BRow,
    BCol,
    vSelect,
  },
  setup() {
    const { getFormattedDate } = helper()
    const {
      getPriorityTranslation,
      getFeedbackTypeTranslation,
      getStatusTranslation,
      getStatusVariant,
      getPriorityVariant,
    } = feedbackHelper()

    return {
      getPriorityTranslation,
      getFeedbackTypeTranslation,
      getStatusTranslation,
      getStatusVariant,
      getPriorityVariant,
      getFormattedDate,
    }
  },
  data() {
    return {
      feedback: {
        feedbackType: '',
        title: '',
        description: '',
        priority: '',
        status: 'open',
        files: [],
        user: '',
      },
      attachments: [],
      feedbackTypeOptions: [
        { label: 'Allgemein', value: 'general' },
        { label: 'Neue Funktion', value: 'feature' },
        { label: 'Fehler', value: 'bug' },
        { label: 'Sonstiges', value: 'other' },
      ],
      priorityOptions: [
        { label: 'Niedrig', value: 'low' },
        { label: 'Mittel', value: 'medium' },
        { label: 'Hoch', value: 'high' },
      ],
      //   feedbackTypeOptions: ['general', 'feature', 'bug', 'other'],
      //   priorityOptions: ['low', 'medium', 'high'],
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters.user
    },
  },

  methods: {
    changedType(item) {
      this.feedback.feedbackType = item.value
    },

    changedPriority(item) {
      this.feedback.priority = item.value
    },

    submitFeedback() {
      if (this.attachments.length > 0) {
        this.uploadFiles()
      } else {
        this.addFeedback()
      }
    },

    addFeedback() {
      this.feedback.user = `${this.currentUser.firstName} ${this.currentUser.lastName}`
      this.$store
        .dispatch('addFeedback', this.feedback)
        .then(() => {
          console.log('addFeedback success')
          this.$store.dispatch('getFeedbackEntries')

          this.$refs.feedbackForm.reset()
          this.feedback.priority = ''
          this.feedback.description = ''
          this.feedback.feedbackType = ''
          this.feedback.title = ''
          this.feedback.files = []
          this.feedback.user = ''
        })
        .catch(err => {
          console.error(err)
        })
    },

    // uploadfiles
    uploadFiles() {
      const formData = new FormData()

      for (let i = 0; i < this.attachments.length; i += 1) {
        const file = this.attachments[i]

        formData.append('files', file)
      }

      const config = {
        onUploadProgress: uploadEvent => {
          console.log(
            `Upload Progress: ${Math.round(
              (uploadEvent.loaded / uploadEvent.total) * 100
            )} %`
          )
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      this.$store
        .dispatch('addFile', formData, config)
        .then(result => {
          // Push the file id to the feedback.files array
          result.forEach(a => this.feedback.files.push(a.id))

          //   this.feedback.files.push result.map(a => a.id)
          this.addFeedback()
        })
        .catch(() => {})
    },

    onFilesChange(event) {
      this.attachments = Array.from(event.target.files)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
