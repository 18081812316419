<template lang="">
  <div>
    <FeedbackForm />
    <FeedbackTableviewVue />
  </div>
</template>
<script>
import FeedbackForm from './FeedbackForm.vue'
import FeedbackTableviewVue from './FeedbackTableview.vue'

export default {
  components: {
    FeedbackForm,
    FeedbackTableviewVue,
  },
}
</script>
<style lang=""></style>
