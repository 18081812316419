export default function feedbackHelper() {
  function getPriorityTranslation(priority) {
    if (priority === 'low') {
      return 'Niedrig'
    }
    if (priority === 'medium') {
      return 'Mittel'
    }
    if (priority === 'high') {
      return 'Hoch'
    }
    return 'Nicht definiert'
  }

  function getFeedbackTypeTranslation(feedbackType) {
    if (feedbackType === 'general') {
      return 'Allgemein'
    }
    if (feedbackType === 'bug') {
      return 'Fehler'
    }
    if (feedbackType === 'feature') {
      return 'Neue Funktion'
    }
    if (feedbackType === 'other') {
      return 'Sonstiges'
    }
    return 'Nicht definiert'
  }

  function getStatusTranslation(status) {
    if (status === 'open') {
      return 'Offen'
    }
    if (status === 'progress') {
      return 'In Bearbeitung'
    }
    if (status === 'done') {
      return 'Erledigt'
    }
    if (status === 'closed') {
      return 'Geschlossen'
    }
    return 'Nicht definiert'
  }

  function getPriorityVariant(priority) {
    if (priority === 'high') {
      return 'danger'
    }
    if (priority === 'medium') {
      return 'warning'
    }
    return 'primary'
  }

  function getStatusVariant(status) {
    if (status === 'open') {
      return 'danger'
    }
    if (status === 'progress') {
      return 'warning'
    }
    if (status === 'done') {
      return 'primary'
    }
    return 'success'
  }

  return {
    getPriorityTranslation,
    getFeedbackTypeTranslation,
    getStatusTranslation,
    getPriorityVariant,
    getStatusVariant,
  }
}
